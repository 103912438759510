<template>
  <div class="Sales">
    <div style=" padding-bottom: 100px;
            height: 100%;
            padding-top: 10px;
            padding-left: 10px;
            padding-right: 10px;
          ">
      <div class="top" style="">
        <div class="flex">
          <el-select v-model="selectKey" style="width: 130px" @change="selectChange">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input :placeholder="placeholder" v-model="queryParam.Keywords" @input="inputChange"
            @keydown.enter.native="search"></el-input>
        </div>
        <!-- <div class="flex" style="margin-top: 10px">
          <el-date-picker v-model="StartTime" type="date" placeholder="开始日期" @change="startTimeChange" value-format="yyyy-MM-dd">
          </el-date-picker>
          <el-date-picker v-model="EndTime" type="date" placeholder="结束日期" @change="endTimeChange" value-format="yyyy-MM-dd">
          </el-date-picker>
        </div> -->
      </div>
      <div style="margin-top: 10px">
        <el-table ref="queryForm" :height="tableHeight" element-loading-text="拼命加载中" :data="tableData"
          v-loading="loading" v-el-table-infinite-scroll="scrollLoad" :infinite-scroll-immediate="false"
          :infinite-scroll-disabled="scrollDisaled">
          <el-table-column :prop="item.prop" :label="item.label" v-for="(item, index) in tablePropListData" :key="index" :fixed="item.label==='客户'">
          </el-table-column>
          <el-table-column label="操作" width="70" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="handleDetail(scope.row)">明细</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- <div
          v-if="tableData.length == pagination.total"
          style="text-align: center; padding-top: 10px"
        >
          没有更多了
        </div> -->
      </div>
    </div>
    <div style="width:100%;position:fixed;bottom:0;padding:20px;background-color:#fff;display:flex;">
      <div style="flex:5">总计:</div>
      <div style="flex:5">{{ OverdueAmount == 0 ?toThousands(OverdueAmount) : toThousands(OverdueAmount) }}</div>
    </div>
  </div>
</template>

<script>
import { toThousands } from "../../../utils/Tools";
export default {
  data() {
    return {
      scrollDisaled: false,
      tableHeight: 200,
      // 千分位格式化
      toThousands,
      // 下拉选择数据
      options: [
        {
          value: "0",
          label: "客户",
        },
        {
          value: "1",
          label: "业务员",
        },
      ],
      // 表格属性数据
      tablePropListData: [
        {
          label: "客户",
          prop: "Name",
        },
        {
          label: "逾期金额",
          prop: "OverdueAmount",
        },
      ],
      loading: false,
      // 开始日期
      StartTime: "",
      // 结束日期
      EndTime: "",
      // 收款统计列表数据
      tableData: [],
      selectKey: "客户",
      placeholder: "请输入客户名称进行查询",
      searchKey: "",
      date: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: [10, 30, 50],
      },
      sorter: {
        field: "CreateTime",
        order: "desc",
      },
      queryParam: {
        CompanyId: "",
        StartDate: "",
        EndDate: "",
        Keywords: "",
        Type: 0,
      },
      // 逾期底部总金额统计数据
      OverdueAmount: 0
    };
  },
  mounted() {
    this.$nextTick(() => {
      // window.innerHeight 浏览器窗口的可见高度，下面的 220 是除了table最大高度的剩余空间。
      let height =
      document.documentElement.clientHeight - this.$refs.queryForm.$el.offsetHeight ;
      this.tableHeight = height+40;
    });
  },
  created() {
    document.title = "逾期统计"


    if (this.$route.query.token) {
      sessionStorage.setItem("token", this.$route.query.token);
    }

    this.queryParam.CompanyId = this.$route.query.CompanyId;
    this.pagination.token = this.$route.query.token;

    console.log(
      "获取路由获取路由获取路由获取路由",
      this.$route.query,
      this.pagination
    );

    this.toGetList();

  },
  methods: {

    toGetList() {
      this.pagination.current = 1;
      this.scrollDisaled = true;
      this.tableData = [];
      // 获取逾期统计列表数据
      this.getCollectionStatistics()
      // 获取逾期底部总金额统计数据
      this.GetOverdueAllStatistics()
    },

    // 搜索
    search() {
      // 同时获取逾期统计列表数据和收款统计总金额数据
      this.toGetList()
    },
    // 获取逾期底部总金额统计数据
    GetOverdueAllStatistics() {
      this.$http.post("/BO/BIlling/GetOverdueAllStatistics", {
        PageIndex: this.pagination.current,
        PageRows: this.pagination.pageSize,
        Search: this.queryParam
      }).then((resJson) => {
        if (resJson.Success) {
          this.loading = false
          console.log('获取逾期统计', resJson.Data)
          if (resJson.Data == null) {
            this.OverdueAmount = 0
            return
          }
          this.OverdueAmount = resJson.Data.OverdueAmount
        } else {
          this.loading = false
        }
      });

    },
    // 表格滚动加载
    scrollLoad() {
      // 监听表格滚动事件
      this.pagination.current++;
      if (this.pagination.current > 1 && this.tableData.length == this.pagination.total) {
        return
      }
      // 获取逾期统计列表数据
      this.getCollectionStatistics()
    },
    // 逾期表格操作明细
    handleDetail(row) {

      let obj = {
        Id: row.Id,
        CompanyId: this.queryParam.CompanyId,
        Type: this.queryParam.Type,
      };

      console.log("当前行1111111111111", row, obj);
      localStorage.setItem("detailObj", JSON.stringify(obj));
      console.log("获取缓存", localStorage.getItem("detailObj"));
      this.$router.push({
        path: "/App/Statistics/AppOverduesDetail",
        query: { obj: obj },
      });
    },

    // 时间选择
    startTimeChange(e) {
      console.log("开始时间", e);
      this.queryParam.StartDate = e;
      this.toGetList();
    },
    endTimeChange(e) {
      console.log("结束时间", e);
      this.queryParam.EndDate = e;
      this.toGetList();
    },
    // 搜索输入框输入
    inputChange(e) {
      console.log("搜索框输入", e);
      this.queryParam.Keywords = e;
    },
    // 分页事件
    currentChange(page) {
      this.pagination.current = page;
      this.toGetList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.toGetList();
    },
    // 获取逾期统计列表数据
    getCollectionStatistics() {
      this.loading = true;
      this.$http
        .post("/BO/BIlling/GetOverdueStatistics", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          Search: this.queryParam,
        })
        .then((resJson) => {
          if (resJson.Success) {

            this.loading = false;

            // 没数据的时候，禁用 infinite-scroll-disabled 滚动事件
            if (resJson.Data.length == 0) {
              this.scrollDisaled = true;
              return
            }

            this.scrollDisaled = false;

            if (resJson.Data) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }

            resJson.Data.forEach((item) => {
              item.OverdueAmount = toThousands(item.OverdueAmount);
            });

            this.tableData = this.tableData.concat(resJson.Data);// 分页请求的数据，追加到任务列表中
            if (this.pagination.current * this.pagination.pageSize >= this.pagination.total) {
              return
            }

          } else {
            this.loading = false;
          }
        });
    },
    // 下拉选择事件
    selectChange(e) {
      this.queryParam.Keywords = "";
      this.tableData = []
      this.pagination.current = 1
      console.log(e);
      if (e == 0) {
        this.placeholder = "请输入客户名称进行查询";
        this.tablePropListData[0].label='客户'
      } else {
        this.placeholder = "请输入业务员名称进行查询";
        this.tablePropListData[0].label='业务员'
      }
     
      // 同步获取逾期列表统计数据和总金额统计数据
      this.toGetList()
    },
  },
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
}

.Sales {
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/App/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 5px;

  .top {
    padding: 10px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
  }
}
</style>
